#kontakt {
    width:100%;

    .btn_anfrage{
        a{
        width:300px; 
        margin:auto;
        display:block; 
        border-radius:3px;
        font-size:11pt;
        text-align:center;
        min-height:100px;
        .icon-send{
            width:20px;
            height:20px;
            background-color:red; 
            }
        }
    }
}  