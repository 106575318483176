#newsletter {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid #CFCFCF;
    

    .newsletter-text {

        h2 {
            text-align: left;
            align-self: center;
            width: 70%;
        }
    }

    .newsletter-form {
        width: 50%;

        label {
            text-transform: uppercase;
            font-size: 8pt;

            // font-weight: bold;
            a {
                color: #00E7B5;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .newsletter-wrap-name {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .newsletter-forname {
                width: 49%;
            }

            .newsletter-lastname {
                width: 49%;
            }
        }
    }


    input[type=password],
    input[type=number],
    input[type=search],
    input[type=email],
    input[type=text],
    input[type=url] {
        width: 100%;
        padding: 3px 5px;
        resize: none;
        margin: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 1.4;
        background-color: #CFCFCF;
        outline: none;
        box-shadow: none;
    }

    input[type=submit] {
        height: 45px;
        width: 100%;
        text-align: center;
        border: none;
        background-color: #00E7B5;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 900px) {
    #newsletter {
        display: flex;
        flex-direction: column;

        .newsletter-text {
            h2 {
                width: 100%;
            }
        }

        .newsletter-form {
            width: 100%;
        } 


        .newsletter-wrap-name {
            width: 100%;
            display: flex;
            flex-direction: column;

            .newsletter-forname {
                width: 100%;
            }

            .newsletter-lastname {
                width: 100%;
            }
        }
    }
}

footer {
    padding-top: 50px;

    // border-top: 1px solid black;
    img {
        width: 45px;
        height: auto;
        opacity: 0.5;
        height: auto;
        margin-right: 10px;
        align-self: center;
    }

    p {
        display: flex;
    }

    .footer-image-wrapper {
        width: 100%;
        display: block;
        clear: both;

        margin-bottom: 10px;
    }

    .wp-block-column {
        &:last-child {
            a {
                color: #333;
                text-decoration: none;
            }

        }
    }
}