@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');

$kontakt-background-color:red;

body {
    color: #333;
}

h1 {
    text-align: center;
    font-size: 16pt;
    width: 100%;
    font-weight: 200;
}

h2 {
    text-align: center;
    font-size: 14pt;
    width: 100%;
    margin-bottom: 20px;
}

h3 {
    color: white;
    padding: 10px;
    background-color: black;
    display: inline-block;
}

h4 {
    color: white;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #E95F4C;
    display: inline-block;
    position: absolute;
    z-index: 1000;
    font-weight: normal;
}

#mehrwerkstatt h5 {
    color: #333;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #69E4B8;
    display: inline-block;
    position: absolute;
    z-index: 1000;
    // font-weight: 700;
    left: 0;
    top: 50px;
    text-decoration: underline;
    background-image: url(../../img/video-play.svg);
    background-repeat: no-repeat;
    background-position: 13px center;
    cursor: pointer;
    background-size: 20px;
    text-indent: 32px;
    font-weight: 600;
}

li,
p {
    line-height: 17pt;
    font-size: 11pt;
}

footer,
#content {
    a {
        color: #00E7B5;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }

    }
}

#content {
    ul {

        li {
            list-style: none;
            position: relative;
            margin-top: 10px;
            margin-bottom: 10px;

            &:before {
                content: '';
                display: block;
                background-image: url('../../img/pfeil-rechts-klein.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 50%;
                width: 20px;
                height: 20px;
                position: absolute;
                left: -30px;

                top: 2px;
            }
        }
    }
}