.blog-content {
    width: 700px;
    max-width: 100%;
    margin: auto;

    .blog-post {
        margin-bottom: 100px;
    }

    .post-image {
        height: 400px;
        width: 100%;
        background-size: cover;
    }
}

.inside-link {
    text-align: center;
    width: 100%;
    display: block;
    margin-bottom: 40px;
    margin-top: 20px;
}