#kundenmeinungen {
    margin-bottom:50px;
    .teaser-outer-wrapper {
        border-top: 1px solid #CFCFCF;
        border-bottom: 1px solid #CFCFCF;
        padding-top: 20px;
        padding-bottom: 20px;
        overflow: hidden;
        position: relative;
        .swiper-button-next-teaser {
            background-image: url('../../img/pfeil-rechts.svg');
           // right: -5%;
            background-size:50%;
            outline:none;
        }  
    
        .swiper-button-prev-teaser {
            background-image: url('../../img/pfeil-links.svg');
           // left: -5%;
            background-size:50%;
            outline:none;
        } 
    
    }

    .teaser-inner-wrapper {
        width: 90%;
        margin: auto;
       
    }


    .swiper-container-teaser {
        overflow: hidden; 
        width: 100%;
        height: 100%;

        position: relative;

       
    }
    .swiper-wrapper{
       //  overflow: hidden; 
    }

    .swiper-slide {
 

        font-size: 18px;
        background: #fff;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        flex-direction: column;

        // -webkit-box-pack: center;
        // -ms-flex-pack: center;
        // -webkit-justify-content: center;
        // justify-content: center;
        // -webkit-box-align: center;
        // -ms-flex-align: center;
        // -webkit-align-items: center;
        // align-items: center;
        .client-logo {
            width: 100px;
            height: 50px;
            background-size: contain;
            background-position: left;
            background-repeat: no-repeat;
        }

        img {
            height: auto;
            width: 100px;
            max-height: 50px;
        }

        p {
            font-weight:400;
            font-style:italic; 
        }
        span{
            font-weight:200;
            font-style:normal;
            font-size: 13px;
        }
    }



}


@media only screen and (max-width: 900px) {
    #kundenmeinungen {
    .teaser-inner-wrapper {
        width: 80%; 
        margin: auto;
        
    }
}
}


@media only screen and (max-width: 600px) {
    #kundenmeinungen {
    .teaser-inner-wrapper {
        width: 70%; 
        margin: auto;
       
    }
}
}
