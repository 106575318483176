.page-id-3,
.page-id-329 {
    #content {

        h1,
        h2,
        h3,
        h4 {
            text-align: left;
            background-color: transparent;
            color: #333;
            margin-left: 0;
            padding:0;
        }
    } 
} 