// .af-field-type-date-time-picker,
// .af-field-type-select {
//    // width: 30% !important;
//     float: left !important;
//     clear: none !important;
//     border: none !important;
// }

// .select2-results__option[aria-selected=true] {
//     text-indent: 30px;
//     position: relative;

//     &:before {
//         content: '';
//         position: absolute;
//         display: block;
//         width: 20px;
//         height: 20px;
//         background-color: red;
//     }
// }

// .select2 {

//     .select2-selection {
//         border: none !important;
//         cursor: pointer !important;
//         background-color: red !important;
//         height: 45px !important;

//         background-color: #ddd;
//         border-radius: 0;
//         -webkit-appearance: none;
//         -moz-appearance: none;
//         appearance: none;
//         text-indent: 54px;
//         background-image: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png");
//         background-repeat: no-repeat;
//         background-position: 10px center;

//         .select2-selection__rendered {
//             display: none !important;
//         }
//     }
// }

#anfrage {

    // @supports not (display: grid) {
    //     .af-field-vorname { order: 2; }
    //  }



    width: 100%;

    // .select2-selection__choice {
    //     display: none;
    // }
    select,
    input[type=email],
    input[type=text],
    input[type=select],
    textarea{
        outline: none;
        box-shadow:none;
        &:focus{
            outline: none;
            box-shadow:none;
        }
       
    }

    select{
        &::-ms-expand {
            display: none;
        }
        option {
            font-weight:normal;
        }
    }

    


    #form {
        width: 100%;
     
        border-top: 1px solid #CFCFCF;
        border-bottom: 1px solid #CFCFCF;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    input[type=select],
    select {
        width: 100%;
        height: 45px;
        border: none;
        background-color: #ddd;
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        text-indent: 10px;
        cursor:pointer;
            background-image: url('../../img/pfeil-unten.svg'); 
            background-repeat: no-repeat;
            background-position: center right 10px; 
            background-size:15px 20px;
            // background-position: 10px center; 
          
        
    }

    .icon {
        background-repeat: no-repeat;
        background-position: left center;
        height: 45px;
        margin-bottom: 10px;
        margin-top: 10px;

        input[type=select],
        select {
            background-size: 15% 50%;
        }
    }

    .icon-timeframe {

        input[type=select],
        select {
            background-image: url('../../img/calender.svg');
        }
    }

    .icon-drinks {

        input[type=select],
        select {
            background-image: url('../../img/drinks.svg');
        }
    }

    .icon-seats {

        input[type=select],
        select {
            background-image: url('../../img/seats.svg');
        }
    }

    .icon-people {

        input[type=select],
        select {
            background-image: url('../../img/people.svg');
        }
    }

    .icon-catering {

        input[type=select],
        select {
            background-image: url('../../img/catering.svg');
        }
    }

    .icon-materials {

        input[type=select],
        select {
            background-image: url('../../img/materials.svg');
        }
    }

    .icon-specials {

        input[type=select],
        select {
            background-image: url('../../img/specials.svg');
        }
    }



    .btn_anfrage {
        a {
            width: 300px;
            margin: auto;
            display: block;
            border-radius: 3px;
            font-size: 11pt;
            text-align: center;
            min-height: 100px;

            .icon-send {
                width: 20px;
                height: 20px;
                background-color: red;
            }
        }
    }
}


/**DESIGN**/
#anfrage {
    label {
        text-transform: uppercase;
        font-size: 8pt;
        cursor: pointer;
    

    }
}

input[type=email],
input[type=text] {
    border: none;
    background-color: grey;
    height: 45px !important;
}



.af-field-type-checkbox {
    .acf-input {
        background-color: #F0F0F0;
    }

    ul {
        li {
            margin: 0 !important;

            label {
                background-color: #CFCFCF;
                height: 45px;
                display: inline-block !important;
                width: auto;
                float: left;
                margin-left: 1px;
                margin-right: 1px;
                margin:1px;
                line-height: 45px;
                padding-left: 10px;
                padding-right: 10px;
                position: relative; 
               
            }

            .selected {
                background-color: #00E7B5;
                &:after{
                    position: absolute;
                    content:'';
                    display:block;
                    width:10px;
                    height:10px;
                    z-index:1;
                    top:0;
                    right:0;
                    background-image: url('../../img/checkmark.svg'); 
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size:contain;
                }
            }

            &:before {
                content: none;
                opacity: 0;
            }
        }
    }

    input[type=checkbox],
    input[type=radio] {
        opacity: 0;
        display: none;
    }

}


/**LAYOUT**/

.af-field-email,
.af-field-firma,
.af-field-nachname,
.af-field-vorname {
    //width: 30% !important;
    // float: left !important;
    clear: none !important;
    border: none !important;

}

.af-field-email {
    grid-column-start: 1;
    grid-column-end: 4;

    input[type=email] {
        background-color: #CFCFCF;

    }
}

.af-field-nachname {
    // grid-column-start: 5;
    // grid-column-end: 7;
    grid-column-start: 4;
    grid-column-end: 6;

    input[type=text] {
        background-color: #CFCFCF;

    }
}

.af-field-datepicker-end,
.af-field-datepicker {
    // widJTJA          th: 20% !important;
    // float: left !important;
    clear: none !important;
    border: none !important;
    padding-right: 1px !important;
    position: relative;
    .acf-date-picker{

        position: relative;
  
        &:before{
            position: absolute;
            content:'';
            display:block;
            width:20px;
            height: 45px;
            z-index:1;
            top:0;
            left:5px;
            background-image: url('../../img/calender.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size:contain;
        }
    }

    input[type=text] {
        text-indent:30px;
        background-color: #F0F0F0;
       
    }

}


.af-field-time-end,
.af-field-time {
    //width: 10% !important;
    // float: left !important;
    clear: none !important;
    border: none !important;
    padding-left: 1px !important;

    label {
        opacity: 0;
    }

    input[type=text] {
        background-color: #F0F0F0;
    }

}



.af-field-anrede {

    grid-column-start: 1;
    grid-column-end: 2;
    
    // width: 10% !important;
    // float: left !important;
    clear: none !important;
    border: none !important;
    padding-right: 1px !important;

    select {
        background-color: #CFCFCF !important;
    }
}

.af-field-vorname {
    grid-column-start: 2;
    grid-column-end: 4;

    // width: 20% !important;
    // float: left !important;
    clear: none !important;
    border: none !important;
    // padding-left: 1px !important;

    input[type=text] {
        background-color: #CFCFCF;

    }

}

.af-field-personen {
    // width: 20% !important;
    // float: left !important;
    clear: none !important;
    border: none !important;

    select {
        background-color: #F0F0F0 !important;
        
    }

}

.af-field-bestuhlung {
    //width: 40% !important;
    // float: left !important;
    clear: none !important;
    border: none !important;
    grid-column-start: 2;
    grid-column-end: 6;


    select {
        background-color: #F0F0F0 !important;
        
    }


}

.af-field-materialien {
    // width: 60% !important;
    // float: left !important;
    clear: none !important;
    border: none !important;
    grid-column-start: 1;
    grid-column-end: 6;
}

.af-field-firma {
    //width: 30% !important;
    // float: left !important;
    clear: none !important;
    border: none !important;
    grid-column-start: 1;
    grid-column-end: 6;

    input[type=text] {
        background-color: #CFCFCF;

    }
}


.acf-field-date-picker {
    grid-column-start: 1;
    grid-column-end: 3;
}
.af-field-datepicker-end {
    grid-column-start: 4;
    grid-column-end: 5;
} 

.af-field-getranke {
    //width: 60% !important;
    // float: left !important;
    clear: none !important;
    border: none !important;
    grid-column-start: 1;
    grid-column-end: 6;

    select {
        background-color: #F0F0F0 !important;
        
    }

}

.af-field-essen {
    // width: 60% !important;
    // float: left !important;
    clear: none !important;
    border: none !important;
    grid-column-start: 1;
    grid-column-end: 6;
}


.af-field-telefon {
    // width: 30% !important;
    // float: left !important;
    clear: none !important;
    border: none !important;
    grid-column-start: 4;
    grid-column-end: 6;

    input[type=text] {
        background-color: #CFCFCF;

    }
}

.af-field-specials {
    // width: 60% !important;
    // float: left !important;
    clear: none !important;
    border: none !important;
    grid-column-start: 1;
    grid-column-end: 6;

}

.af-field-bemerkung {
    //width: 30% !important;
    // float: left !important;
    clear: none !important;
    border: none !important;
    // grid-column-start: 5;
    // grid-column-end: 7;
    // grid-row-start: 6;
    // grid-row-end: 8;

    grid-column-start: 1;
    grid-column-end: 6;

    textarea {
        border: none;

        height:72px !important;

        background-color: #CFCFCF;


    }
}

.af-field-servicepersonal {
    // width: 60% !important;
    // float: left !important;
    clear: none !important;
    border: none !important;
    grid-column-start: 1;
    grid-column-end: 6;

    grid-row-start: 7;
    grid-row-end: 9;
}

.acf-fields {
    display: grid;
    justify-content: flex-start;
    // grid-template-columns: 23.3% 10% 23.3% 10% 10% 23.3%;
    grid-template-columns: 17.5% 17.5% 15% 35% 15%;
    // flex-direction: row;
    // flex-wrap: wrap;
}

.acf-field {
    margin: 15px 0;
    clear: both;
 
}

.af-field-datenschutz{
    grid-column-start: 1;
    grid-column-end: 5;
    border-top: none!important;
    // background-color:red;
    position:relative;
    // &:after{
    //     content:'Hiermit bestätige das ich die <a href="#">Datenschutzerklärung</a> gelesen habe.';
    //     display:block;
    //     position:absolute;
    //     font-size: 8pt;
    //     left: 40px;
    //     top: 25px;
    // }
}

.acf-form-submit {

    // grid-column-start: 5;
    // grid-column-end: 7;
    // border-top: #EEEEEE solid 1px;
    .af-submit-button {
        height: 45px;
        width: 100%;
        text-align: center;
        border: none;
        background-color: #00E7B5;
    }
}

.acf-required {
    color: #f00;
    display: none;
}

.form-desktop{
    width:calc(100% + 24px);
    margin-left:-12px;
}

.form-mobile{  
    display:none !important;  
    width:calc(100% + 24px);
    margin-left:-12px;
}

@media (max-width: 782px) {

    .form-desktop {
        width: 100%;
        margin: auto;
        .af-field {
            grid-column-start: 1;
            grid-column-end: 6;
            padding-left: 0px !important;
            padding-right: 1px !important;
        }

        .af-submit {
            grid-column-start: 1;
            grid-column-end: 6;
            padding-left: 0px !important;
            padding-right: 1px !important;
        }
    }

//     .form-desktop{
//         display:none !important; 
//     }
//     .form-mobile{  
//         display:block !important; 
//     }

//     .acf-fields {
//         display: grid;
//         justify-content: flex-start;
//         grid-template-columns: 25% 75%;
     
//     }

    
//     .af-field-anrede{
//         grid-column-start: 1;
//         grid-column-end: 1;
//     }
//     .af-field-vorname {
//         grid-column-start: 2;
//         grid-column-end: 3;
//     }
//     .af-field-telefon,
//     .af-field-email,
//     .af-field-firma,
//     .af-field-nachname{
//     grid-column-start: 1;
//     grid-column-end: 3;
//     }

//     .af-field-bemerkung {
//         grid-column-start: 1;
//         grid-column-end: 3;
//         grid-row-start: auto;
//         grid-row-end: auto;
//     }
//     .acf-form-submit {
//         grid-column-start: 1;
//         grid-column-end: 3;
//     }    

}


.acf-error{
    input[type=email],
    input[type=text], 
    input[type=select], 
    input[type=checkbox],  
      textarea{
          background-color:red !important;
      }

      input[type=checkbox]{
          outline: 1px solid red !important;
      }
 
    .acf-input{
   
        .acf-error-message{
            display:none;
        }
    }
}

.acf-error-message{
    display:none !important;
}

.af-field-required{
  
    .acf-label{
        pointer-events: none;
    }
    
}
.af-success{
    width:100%;
    height:200px;
    display:block;
    background-color: #00E7B5;
    color:black;
    font-size:34pt;
    font-weight:600;
    text-align:center;
    p{
        padding-top: 90px;
    }

}
.af-submit{
    position: relative;
.acf-spinner{

    position: absolute;
    z-index: 20;
    top: 27px;
    left: 20px;
}
}




