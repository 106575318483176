@import "fonts"; 

@import "structure"; 
@import "variables"; 
@import "navigation"; 
@import "header"; 
@import "cookie";  
@import "footer";

@import "single";

@import "pages/404"; 
@import "pages/kontakt"; 
@import "pages/mehrwerkstatt";  
@import "pages/kundenmeinungen";  
@import "pages/specials";  
@import "pages/anfrage"; 
@import "pages/location";  
@import "pages/datenschutz";    
@import "pages/welcome";    
@import "pages/blog";  
// @import "global";                                      