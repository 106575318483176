body {
  font-family: 'Open Sans', sans-serif !important;
}

.notfound-404 {
  font-size: 28px;
  line-height: 50px;
  text-align: center;
}

.cc-banner {
  .cc-deny {
    display: none !important;
  }
}

img {
  width: 100%;
}

#wrapper {
  max-width: 1200px;
  margin: auto;

  .wp-block-column {
    //margin: 20px;

    //margin:0 !important;  
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .section {
    position: relative;
  }
}

.wp-block-image figcaption {
  color:#999 !important;
  text-align: left!important;
}

@media (max-width: 782px) {
  #wrapper {

    .wp-block-column {
      // margin: 20px; 

      //margin:0 !important;  
      // &:first-child {
      //   margin-left: 20px;
      // }

      // &:last-child {
      //   margin-right: 20px;
      // }
    }

  }
}

@media (min-width: 600px) {
  .wp-block-column {
    flex-basis: 100% !important;
  }
}

@media only screen and (max-width: 1200px) {
  #wrapper {
    width: 95%;
    margin: auto;
  }
}


.vex-dialog-buttons {
  display: none;
}

.vex.vex-theme-wireframe .vex-content {
  max-width: 95%!important;
  width: 800px!important;
  iframe{
    width: 100%;
  }
}

.vex-content {
  padding-top: 53px!important;
  background: transparent!important;
  border: none!important;
}

.vex-dialog-input {
  position: relative;
  padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
  height: 0;
  overflow: hidden;
}

.vex iframe {
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%;
}

.vex-close:before {
  color: white!important;
}


.sixteen-nine {
  position: relative;
  overflow: hidden;
}

.sixteen-nine:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 56.25%;
}


.sixteen-nine>.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}



.four-three {
  position: relative;
  overflow: hidden;
}

.four-three:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 75%;
}

.four-three>.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
