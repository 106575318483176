header {


    height: auto;
    //background-color: #ddd;
    color: #555;
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-top: 20px;

    .header-top {
        position: relative;
        height: 130px;
        width: 100%;

        // background-color:red;
        .social-media {
            position: absolute;
            left: 0;
            top: 0;
            width: 100px;
            height: 30px;
            display: block;

            // background-color:green;
            .icon {
                width: 20px;
                height: 20px;
                // display:block;
                // background:#888;
                // border-radius:50%;
                display: inline-block;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                opacity: .5;
                -webkit-transition: opacity .2s ease-in-out;
                -moz-transition: opacity .2s ease-in-out;
                -ms-transition: opacity .2s ease-in-out;
                -o-transition: opacity .2s ease-in-out;
                transition: opacity .2s ease-in-out;

                &:hover {
                    opacity: 1;
                }
            }

            .icon-fb {
                background-image: url('../../img/facebook.svg');
            }

            .icon-tw {
                background-image: url('../../img/twitter.svg');
            }

            .icon-yt {
                background-image: url('../../img/youtube.svg');
            }

            .icon-in {
                background-image: url('../../img/instagram.svg');
            }
        }

        #logo-offsite-location {
            background-image: url('../../img/genau-mein-raum.svg');
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            width: 130px;
            height: 130px;
            margin: auto;

            position: absolute;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }

        .logo-mehrwerkstatt {
            background-image: url('../../img/mehrwerkstatt-event-logo.svg');
            background-repeat: no-repeat;
            background-size: contain;

            position: absolute;
            right: 0;
            top: 0;

            width: 80px;
            height: 100px;
            display: block;
        }

        .close-single {
            position: absolute;
            right: 0;
            top: 0;
            background-image: url('../../img/schliessen.svg');
            background-size: 13px;
            padding-right: 25px;
            background-repeat: no-repeat;
            background-position: right;
            width: 80px;
            display: block;
        }
    }
}

