#specials{
    position:relative;
    border-top:1px solid #CFCFCF;
   
    padding-top: 20px;
    padding-bottom: 20px;  
    h4{
        top:40px; 
    }
    #border-section{
        border-top:1px solid #CFCFCF;
        border-bottom:1px solid #CFCFCF;     
        padding-top: 20px;
        padding-bottom: 20px;  
        #btn-scroll-up{
            width: 300px;
            margin: auto;
            display: block;
            border-radius: 3px;
            font-size: 11pt;
            text-align: center;
            min-height: 80px;
            background-color: #00E7B5; 
            color:black;
            font-weight:bold;
             position:relative;
           padding-bottom:40px;
             span{
                top: 60%;
                position: absolute;
                text-align: center;
                left: 0; 
                width:100%;
             }

           &:before{
               content:'';
                background-image: url('../../img/pfeil-im-kreis-oben.svg');    
                background-repeat: no-repeat;
                background-size: contain;
                display:block;
                margin:auto; 
                width:20px;
                height:20px;
                top:40%;
                transform: translateY(-50%);
                position:absolute;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                 
            }
        }
    }

    // @media only screen and (max-width: 700px) {
    //     .wp-block-column{
    //      margin:0;
    //     }
    //   }

  
}