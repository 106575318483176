#mehrwerkstatt {
  position: relative;
  overflow: hidden;

  h4 {
    font-weight: bold;
    left: 0;
  }

  #text-wrapper>* {
    /* Your style */
    padding: 20px;
  }

  a.btn-down {

    margin: auto;
    display: block;
    border-radius: 3px;
    font-size: 11pt;
    text-align: left;
    color: black;
    background-color: $contrast-color;
    margin: 25px;
    position: relative;
    font-weight: normal;
    padding-left: 50px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;

    &:before {
      content: '';
      display: block;
      position: absolute;
      background-image: url('../../img/pfeil-im-kreis-unten.svg');

      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
    }


  }


  #gallery-wrapper {
    width: 70%;
    position: relative;
    float: left;
    overflow:hidden;
    padding-bottom: 50px;
  }



  #text-wrapper {
    float: left;
    width: 30%;

    p {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .swiper-container {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;

    .swiper-slide {
      background-size: cover;
      background-position: center;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .gallery-top {
    height: 80%;
    width: 100%;

    .swiper-wrapper {
      position: absolute;
      top: 0;
    }
  }

  .gallery-thumbs {
    //   height: 20%;
    height: 100px;
    box-sizing: border-box;
    // padding: 10px 2%;
    padding: 6px 20px;
    position: absolute;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 20px;
    background-color: black;

    .swiper-button-next,
    .swiper-button-prev {
      background-size: 50%;
    }

    .swiper-button-prev {
      left: -4px;
    }

    .swiper-button-next {
      right: -4px;
    }

    // padding: 0;
    // padding-left: 2%;
  }

  .gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;

  }

  @media only screen and (max-width: 1000px) {
    #gallery-wrapper {
      width: 100%;
      float: none;
    }

    #text-wrapper {
      width: 100%;
      float: none;

      p {
        padding-left: 0;
        padding-right: 0;
      }

      a {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .gallery-thumbs {
      //   height: 20%;
      // height: 80px;
    }
  }

  .swiper-container {
    overflow: visible !important; 
  }

  .swiper-pagination {
    bottom: -30px;
  }

  .swiper-pagination-bullet-active {
    background-color: black;
  }

  @media only screen and (max-width: 700px) {
    .gallery-thumbs {
      //   height: 20%;
      height: 60px;
      position: relative;
      bottom: auto;
      width: 100%;
    }
  }
}