#blog {
    margin-top: 40px;

    img {
        margin-bottom: 30px;
        height: auto;
    }

    border-bottom: 1px solid #CFCFCF;
}

.single-post {

    .single-content {
        width: 700px;
        max-width: 100%;
        margin: auto;
    }

    .single-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        border-bottom: 1px solid #CFCFCF;
        padding-bottom: 80px;
    }

    .wp-block-image figcaption {
        color: rgb(180, 180, 180) !important;
        text-align: left;
    }

    .next,
    .previous {
        text-decoration: underline;
        min-width: 200px;

        a {
            font-style: italic;
            color: #7C7D7D !important;

        }
    }


    .home {
        display: block;
        position: relative;
        border-radius: 3px;
        font-size: 11pt;
        text-align: left;
        color: black !important;
        background-color: #00E7B5;
        font-weight: normal;
        padding: 15px;
        padding-left: 50px;
        line-height: 14px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            background-image: url('../../img/pfeil-im-kreis-unten.svg');

            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: contain;
            left: 15px;
            // top: 50%;
            // transform: translateY(-50%);
            transform: rotate(90deg);

        }
    }
}